<template>
  <div class="quality-assurance">
    <head-nav></head-nav>
    <div class="quality-assurance-banner">
      <img
        :src="$t('quality-assurance.home-banner')"
        alt=""
      />
    </div>
    <div class="quality-assurance-box1">
      <div class="wrap">
        <div class="quality-assurance-top">
          <h3 class="quality-assurance-top-title">
            <img
              :src="$t('quality-assurance.title-1')"
              alt=""
            />
          </h3>
          <div class="quality-assurance-top-img">
            <div class="quality-assurance-top-imgs">
              <img
                :src="$t('quality-assurance.banner')"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="quality-assurance-box2">
      <div class="wrap">
        <h3 class="quality-assurance-title">
          <img
            :src="$t('quality-assurance.title-2')"
            alt=""
          />
        </h3>

        <div class="quality-assurance-img">
          <div
            class="quality-assurance-imgs"
            v-for="(item, index) in $t('quality-assurance.assuranceInfo')"
            :key="index"
            @click.stop="onClickOpen(item.id)"
          >

            <img
              :src="item.img"
              :alt="item.title"
            />
            <div class="mask">
              <p>{{ item.title }}</p>
            </div>

            <transition name="nameoftransition">
              <div
                v-if="detailTopId === item.id"
                class="listEnlargeImg"
              >
                <i
                  class="iconfont icon-cha"
                  @click.stop="onClickclone()"
                ></i>

                <div class="list-img">

                  <div class="img-info">
                    <div class="img-info-list"
                      v-for="(items) in item.list"
                      :key="items.id"
                    >
                      <h3>{{items.title}}</h3>

                      <div>
                        <img :src="items.img" alt="">
                      </div>

                    </div>

                  </div>

                </div>

              </div>
            </transition>

            <div
              class="listEnlargeImg-mask"
              :style="isMaskShow?'opacity: 1;z-index:9;':''"
            ></div>

          </div>
        </div>
      </div>
    </div>

    <div class="quality-assurance-box3">
      <div class="wrap">
        <h3 class="quality-assurance-title">
          <img
            :src="$t('quality-assurance.title-3')"
            alt=""
          />
        </h3>

        <div class="quality-assurance-img">
          <div
            class="quality-assurance-imgs"
            v-for="(item, index) in $t('quality-assurance.assuranceImg')"
            :key="index"
            @click.stop="onClickOpen(item.id)"
          >

            <img
              :src="item.img"
              :alt="item.title"
            />
            <div class="mask">
              <p>{{ item.title }}</p>
            </div>

            <transition name="nameoftransition">
              <div
                v-if="detailTopId === item.id"
                class="listEnlargeImg"
              >
                <i
                  class="iconfont icon-cha"
                  @click.stop="onClickclone()"
                ></i>

                <div class="list-img">

                  <div class="img-info">
                    <div class="img-info-list"
                      v-for="(items) in item.list"
                      :key="items.id"
                    >
                      <h3>{{items.title}}</h3>

                      <div>
                        <img :src="items.img" alt="">
                      </div>

                    </div>

                  </div>

                </div>

              </div>
            </transition>

            <div
              class="listEnlargeImg-mask"
              :style="isMaskShow?'opacity: 1;z-index:9;':''"
            ></div>

          </div>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import HeadNav from './head-nav';

import Footers from './footers';

export default {
  title: 'Change direction',
  components: {
    HeadNav,
    Footers,
  },
  data() {
    return {
      isMaskShow: false,
      detailTopId: 0,
      banner: [],
      assuranceList: [],
    };
  },
  computed: {
    assurance1() {
      return this.assuranceList.filter((item) => item.type === 'rawMaterial');
    },
    assurance2() {
      return this.assuranceList.filter((item) => item.type === 'productDimnesion');
    },
  },
  methods: {
    onClickOpen(ID) {
      this.detailTopId = ID;
      this.isMaskShow = true;
      console.log('111');
      console.log(ID);
    },
    onClickclone() {
      this.detailTopId = 0;
      this.isMaskShow = false;
    },
    toLink(id) {
      this.$router.push({
        name: 'quality-assurance-details',
        params: {
          id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.nameoftransition-enter-active,
.nameoftransition-leave-active {
  opacity: 1;
  transform: scale(0);
}
.nameoftransition-enter,
.nameoftransition-leave-to {
  opacity: 0;
}
.quality-assurance {
  .quality-assurance-banner {
    width: 100%;
    height: auto;

    img {
      width: 100%;
      height: auto;
    }
  }

  .quality-assurance-box1 {
    background: #fff;

    .quality-assurance-top {
      padding: 90px 0 80px 0;

      .quality-assurance-top-title {
        text-align: center;
        margin-bottom: 30px;
      }
    }
  }

  .quality-assurance-box2 {
    width: 100%;
    height: 500px;
    background: #eee;

    .wrap {
      padding: 90px 0 30px 0;

      .quality-assurance-title {
        text-align: center;
      }

      .quality-assurance-img {
        margin-top: 40px;
        position: relative;

        .quality-assurance-imgs {
          cursor: pointer;

          .listEnlargeImg-mask {
            cursor: default;
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.1);
            opacity: 0;
            z-index: -1;
          }

          .listEnlargeImg {
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            z-index: 10;
            transition: all 0.4s;

            .icon-cha {
              position: absolute;
              left: 12%;
              top: 5%;
              color: #fff;
              font-size: 24px;
            }

            .list-img {
              position: absolute;
              margin-left: auto;
              max-width: 1100px;
              border-radius: 3px;
              background: white;
              box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
               rgba(15, 15, 15, 0.1) 0px 5px 10px,
                rgba(15, 15, 15, 0.2) 0px 15px 40px;
              top: 72px;
              left: 72px;
              right: 72px;
              margin-right: auto;
              height: calc(100% - 144px);
              overflow: auto;
              padding-bottom: 40px;

              &::-webkit-scrollbar-track-piece {
                  background-color: #f8f8f8;
                }
                &::-webkit-scrollbar {
                  width: 14px;
                  height: 12px;
                }
                &::-webkit-scrollbar-thumb {
                  background-color: #ddd;
                  background-clip: padding-box;
                  min-height: 28px;
                  border-radius: 20px;
                  height: 9px;
                }
                &::-webkit-scrollbar-thumb:hover {
                  background-color: #ccc;
                }

            }

            .img-info {
              width: 100%;
              color: #000;

              &-list{
                margin-top: 10px;
                h3{
                  text-align: center;
                }

                img {
                width: 100%;
                margin: 10px 0;
              }
              }

            }

            p {
              text-align: center;
              margin: 10px 0;
              color: #999;
            }
          }

          &:hover {
            .mask {
              line-height: 200px;
              height: 200px;
              color: #000;
              background: #ffbf00;
              font-weight: 600;
            }
          }

          position: absolute;
          background: #666666;
          width: 300px;
          height: 200px;
          margin: 30px auto;
          color: #fafafa;
          line-height: 24px;

          img {
            width: 100%;
            height: 100%;
          }

          .mask {
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
            background: #000;
            opacity: 0.7;
            text-align: center;
            height: 60px;
            line-height: 60px;
          }
        }

        .quality-assurance-imgs:nth-child(1) {
          top: 0;
          left: 0;
        }

        .quality-assurance-imgs:nth-child(2) {
          top: 0;
          left: 300px;
        }

        .quality-assurance-imgs:nth-child(3) {
          top: 0;
          left: 600px;
        }
        .quality-assurance-imgs:nth-child(4) {
          top: 0;
          left: 900px;
        }

        .quality-assurance-imgs:nth-child(2n + 2) {
          .mask {
             top: 0  !important;
          }

          &:hover {
            .mask {
              line-height: 200px;
              height: 200px;
              background: #ffbf00;
              font-weight: 600;

            }
          }
        }
      }
    }
  }

  .quality-assurance-box3 {
    width: 100%;
    height: 700px;
    background: #fff;
    .wrap {
      padding: 90px 0 160px 0;

      .quality-assurance-title {
        text-align: center;
      }

       .quality-assurance-img {
        margin-top: 40px;
        position: relative;

        .quality-assurance-imgs {
          cursor: pointer;

          .listEnlargeImg-mask {
            cursor: default;
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.1);
            opacity: 0;
            z-index: -1;
          }

          .listEnlargeImg {
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            z-index: 10;
            transition: all 0.4s;

            .icon-cha {
              position: absolute;
              left: 12%;
              top: 5%;
              color: #fff;
              font-size: 24px;
            }

            .list-img {
              position: absolute;
              margin-left: auto;
              max-width: 1100px;
              border-radius: 3px;
              background: white;
              box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px;
              top: 72px;
              left: 72px;
              right: 72px;
              margin-right: auto;
              height: calc(100% - 144px);
              overflow: auto;
              padding-bottom: 40px;

              &::-webkit-scrollbar-track-piece {
                  background-color: #f8f8f8;
                }
                &::-webkit-scrollbar {
                  width: 14px;
                  height: 12px;
                }
                &::-webkit-scrollbar-thumb {
                  background-color: #ddd;
                  background-clip: padding-box;
                  min-height: 28px;
                  border-radius: 20px;
                  height: 9px;
                }
                &::-webkit-scrollbar-thumb:hover {
                  background-color: #ccc;
                }

            }

            .img-info {
              width: 100%;
              color: #000;

              &-list{
                margin-top: 10px;
                h3{
                  text-align: center;
                }

                img {
                width: 100%;
                margin: 10px 0;
              }
              }

            }

            p {
              text-align: center;
              margin: 10px 0;
              color: #999;
            }
          }

          &:hover {
            .mask {
              line-height: 200px;
              height: 200px;
              color: #000;
              background: #ffbf00;
              font-weight: 600;
            }
          }

          position: absolute;
          background: #666666;
          width: 400px;
          height: 200px;
          margin: 30px auto;
          color: #fafafa;
          line-height: 24px;

          img {
            width: 100%;
            height: 100%;
          }

          .mask {
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
            background: #000;
            opacity: 0.7;
            text-align: center;
            height: 60px;
            line-height: 60px;
          }
        }

        .quality-assurance-imgs:nth-child(1) {
          top: 0;
          left: 0;
        }

        .quality-assurance-imgs:nth-child(2) {
          top: 0;
          left: 400px;
        }

        .quality-assurance-imgs:nth-child(3) {
          top: 0;
          left: 800px;
        }
        .quality-assurance-imgs:nth-child(4) {
          top: 200px;
          left: 0px;
        }

        .quality-assurance-imgs:nth-child(5) {
          top: 200px;
          left: 400px;
        }

        .quality-assurance-imgs:nth-child(6) {
          top: 200px;
          left: 800px;
        }

        .quality-assurance-imgs:nth-child(2n + 2) {
          .mask {
            background: #fff;
            color: #000;
          }

          &:hover {
            .mask {
              line-height: 200px;
              height: 200px;
              background: #ffbf00;
              font-weight: 600;
            }
          }
        }
      }

    }
  }
}

@media screen and (max-width: 1200px) {
  .quality-assurance .quality-assurance-banner,
  .quality-assurance .quality-assurance-banner img,
  .quality-assurance .quality-assurance-box1 .quality-assurance-top .quality-assurance-top-img,
  .quality-assurance .quality-assurance-box1 .quality-assurance-top .quality-assurance-top-imgs,
  .quality-assurance .quality-assurance-box1 .quality-assurance-top .quality-assurance-top-imgs img,
  .quality-assurance .quality-assurance-box2 {
    width: 1200px;
  }

  .quality-assurance .quality-assurance-box2 .wrap .quality-assurance-img .quality-assurance-imgs {
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
